.loading {
  right: 5%;
  opacity: 1;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-duration: 8s, 0s;
}

.loader {
  boxshadow: '0 1px 8px hsla(0, 0%, 0%, 0.1)';
  transitionproperty: 'right, opacity';
  transitionduration: '0s';
}
